import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import ToolsLayout from "../../components/toolsLayout";
import SEO from "../../components/seo";
import Grid from "../../components/grid.js";
import { toolImages, defaultToolIllustration } from "../../utils/gridImages";

const ToolsPage = (props) =>
{
  const { site } = useStaticQuery(
    graphql`
      query
      {
        site
        {
          siteMetadata
          {
            tools
            {
              id
              name
              description
              link
            }
          }
        }
      }
    `
  );
  
  return (
    <ToolsLayout
      show = { false }
    >
      <SEO
        keywords = { [`LawChest`, `tools`, `tool`] }
        title = "Tools"
      />

      <section
        id = "utilitySection"
        className = "text-center"
      >
        <Grid
          gridElements = { site.siteMetadata.tools }
          elementImages = { toolImages }
          defaultElementImage = { defaultToolIllustration }
          isAdminPage = { false }
        />
      </section>
    </ToolsLayout>
  );
}

export default ToolsPage;
